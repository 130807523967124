<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard v-if="language.languageId" no-header>
        <CCardBody>
          <h3>{{ operationName }} Language</h3>
          <CAlert :color="alertType" v-if="message"> {{message}} </CAlert>
          <CSelect label="DateTime Format" :options="dateTimeFormats" :value.sync="language.datetimeFormat">
          </CSelect>
          <CSelect
          v-if="$route.params.id == emptyGuid"
            label="Language"
            :value.sync="language.languageId"
            :plain="true"
            :options="languages"
          >
          </CSelect>

          <CButton color="secondary" @click="goBack" style="margin-right: 15px"
            ><CIcon name="cil-list"/> Back to the list</CButton
          >

          <CButton color="primary" @click="create()"><CIcon name="cil-save"/> Save</CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import Vue from 'vue';

export default {
  name: "AddNewLanguage",
  data: () => {
    return {
      operationName: "Create New",
      partnerId: localStorage.getItem("partner_id"),
      token: localStorage.getItem("api_token"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      languages: [],
      dateTimeFormats: [
        {
          value: null,
          label: "Choose"
        },
        {
          value: "dd.MM.yyyy HH:mm",
          label: "dd.MM.yyyy hh:mm"
        },
        {
          value: "dd.MM.yyyy hh:mm tt",
          label: "dd.MM.yyyy hh:mm AM/PM"
        },
        {
          value: "dd-MM-yyyy HH:mm",
          label: "dd-MM-yyyy hh:mm"
        },
        {
          value: "dd-MM-yyyy hh:mm tt",
          label: "dd-MM-yyyy hh:mm AM/PM"
        },
        {
          value: "MM/dd/yyyy HH:mm",
          label: "MM/dd/yyyy hh:mm"
        },
        {
          value: "MM/dd/yyyy hh:mm tt",
          label: "MM/dd/yyyy hh:mm AM/PM"
        },
        {
          value: "MM-dd-yyyy HH:mm",
          label: "MM-dd-yyyy hh:mm"
        },
        {
          value: "MM-dd-yyyy hh:mm tt",
          label: "MM-dd-yyyy hh:mm AM/PM"
        },
      ],
      language: {
        languageId: null,
      },
      // Alert
      message: null,
      alertType: "danger",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    create() {
      let self = this;
      if(self.language.datetimeFormat){
        self.language.datetimeFormatWidget = self.dateTimeFormats.find(format => format.value == self.language.datetimeFormat).label;
      }
      axios
        .post(
          `${this.$apiAdress}/v1/PartnerLanguage`,
          self.language
        )
        .then(function () {
          axios
        .get(`${self.$apiAdress}/v1/Language/GetByCode/en-US`)
        .then(function (response) {
          Vue.prototype.$dateFormat = response.data.datetimeFormat.replace("dd", "DD").replace("tt", "A");
          Vue.prototype.$dateFormatInput = response.data.datetimeFormat.replace("tt", "a");
        });
          self.goBack();
        })
        .catch(function (error) {
          self.message = error;
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
        });
    },
    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/ListItem/Languages`)
        .then(function (response) {
          self.languages = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    get() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerLanguage/GetById/${self.$route.params.id}`)
        .then(function (response) {
          self.language = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getLanguages();
    if (this.$route.params.id != this.emptyGuid) {
      this.operationName = "Edit";
      this.get(this.$route.params.id);
    }
  },
};
</script>
